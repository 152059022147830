import React from 'react';
import {
  SignIn,
  ForgotPassword,
  Greetings,
  RequireNewPassword,
} from 'aws-amplify-react';
import Authenticator from 'Auth/Authenticator';
import CustomSignIn from 'Auth/SignIn';
import CustomGreetings from 'Auth/Greetings';
import CustomRequireNewPassword from 'Auth/RequireNewPassword';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import trimStart from 'lodash/trimStart';
import ErrorMessageMap from 'Auth/ErrorMessageMap';

const handleAuthStateChange = async (
  state: string,
  data: any,
  redirect?: string
) => {
  if (state === 'signedIn' && data != null) {
    try {
      if (redirect) {
        window.location.href = redirect;
      }
    } catch (error) {
      console.error(error);
    }
  }
};

/**
 * This component handles all auth states except 'forgot password'.
 * Password reset is handled in a separate component in order
 * render it based on the URL.
 */
const Auth: React.FC = ({ children }) => {
  const location = useLocation();
  const queryParams = qs.parse(trimStart(location.search, '?'));

  return (
    <Authenticator
      hide={[SignIn, ForgotPassword, Greetings, RequireNewPassword]}
      onStateChange={(state, data) => {
        return handleAuthStateChange(state, data, queryParams['redirect']);
      }}
      errorMessage={ErrorMessageMap}
    >
      <CustomSignIn />
      <CustomRequireNewPassword />
      <CustomGreetings />
      {children}
    </Authenticator>
  );
};

export default Auth;
