import React from 'react';
import { SignIn } from 'aws-amplify-react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Button from '@bit/greenenergytrading.geo.inputs.button';
import styles from 'Auth/styles';
import Logo from '@bit/greenenergytrading.geo.brand.logo';

export default withStyles(styles)(
  class CustomSignIn extends SignIn {
    constructor(props) {
      super(props);
      this._validAuthStates = ['signIn', 'signedOut', 'signedUp'];
    }

    getUsernameFromInput() {
      const username = this.inputs.username || this.state.username;
      return username.toLowerCase().trim();
    }

    showComponent(theme) {
      return (
        <form autoComplete="off">
          <Card className={this.props.classes.root}>
            <CardContent>
              <div style={{ textAlign: 'center' }}>
                <Logo width={150} />
                <Typography
                  variant="h1"
                  style={{ fontSize: 34, marginBottom: 20 }}
                >
                  Welcome to Geo!
                </Typography>
              </div>
              <Grid container>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="username"
                    key="username"
                    name="username"
                    onChange={this.handleInputChange}
                    label="Email"
                    type="email"
                    margin="normal"
                    variant="outlined"
                    inputProps={{
                      'data-test': 'username-input',
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="password"
                    key="password"
                    name="password"
                    onChange={this.handleInputChange}
                    label="Password"
                    type="password"
                    margin="normal"
                    variant="outlined"
                    inputProps={{
                      'data-test': 'sign-in-password-input',
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Typography variant="body2" className={this.props.classes.link}>
                <Button
                  size="small"
                  variant="text"
                  onClick={() => (window.location.href = '/forgot_password')}
                >
                  Forgot your password?
                </Button>
              </Typography>
            </CardContent>
            <CardActions className={this.props.classes.actions}>
              <Button
                type="button"
                onClick={event => super.signIn(event)}
                data-test="sign-in-sign-in-button"
                variant="contained"
                color="primary"
                loading={this.state.loading}
              >
                Login
              </Button>
            </CardActions>
          </Card>
        </form>
      );
    }
  }
);
