import React from 'react';
import { RequireNewPassword } from 'aws-amplify-react';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import styles from 'Auth/styles';
import PasswordInput from './PasswordInput';
import PasswordConfirm from './PasswordConfirm';

export default withStyles(styles)(
  class CustomRequireNewPassword extends RequireNewPassword {
    constructor(props) {
      super(props);
      this._validAuthStates = ['requireNewPassword'];

      // The parent component only serializes the inputs when
      // making XHR requests. Therefore, we must keep redundant
      // copies in state to use for real-time user feedback.
      if (!this.state) {
        this.state = {};
      }
      this.state.password = '';
      this.state.passwordConfirm = '';
    }

    get isPasswordSet() {
      return this.state.password.trim().length > 0;
    }

    get isPasswordConfirmed() {
      return this.state.passwordConfirm === this.state.password;
    }

    showComponent(theme) {
      const user = this.props.authData;
      const { requiredAttributes } = user.challengeParam;

      return (
        <Card
          className={this.props.classes.root}
          data-test="require-new-password-section"
        >
          <CardContent data-test="require-new-password-body-section">
            <div data-test="require-new-password-header-section">
              <Typography
                variant="h3"
                style={{ fontSize: 24, marginBottom: 20 }}
              >
                Change Password
              </Typography>
              <Typography variant="body2" style={{ marginBottom: 20 }}>
                Please choose a new password to replace the temporary one we've
                issued you.
              </Typography>

              <PasswordInput
                password={this.state.password}
                onChange={event => {
                  this.handleInputChange(event);
                  this.setState({ password: event.target.value });
                }}
              />

              <PasswordConfirm
                isConfirmed={this.isPasswordSet && this.isPasswordConfirmed}
                onChange={event => {
                  this.handleInputChange(event);
                  this.setState({ passwordConfirm: event.target.value });
                }}
              />
            </div>

            {requiredAttributes.map(attribute => (
              <TextField
                required
                id={attribute}
                key={attribute}
                name={attribute}
                onChange={this.handleInputChange}
                label={convertToPlaceholder(attribute)}
                type="text"
                margin="normal"
                variant="outlined"
                autoComplete="off"
                fullWidth
              />
            ))}
          </CardContent>
          <CardActions className={this.props.classes.actions}>
            <Button
              onClick={() => this.changeState('signIn')}
              data-test="require-new-password-back-to-sign-in-link"
            >
              Back to sign in
            </Button>

            <Button onClick={this.change} variant="contained" color="primary">
              Update password
            </Button>
          </CardActions>
        </Card>
      );
    }
  }
);

function convertToPlaceholder(str) {
  return str
    .split('_')
    .map(part => part.charAt(0).toUpperCase() + part.substr(1).toLowerCase())
    .join(' ');
}
