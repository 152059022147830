import React from 'react';
import ReactCodeInput from 'react-code-input';
import { ForgotPassword, AmplifyTheme } from 'aws-amplify-react';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import EmailIcon from '@material-ui/icons/Drafts';
import Grid from '@material-ui/core/Grid';
import Button from '@bit/greenenergytrading.geo.inputs.button';
import { withStyles } from '@material-ui/core/styles';
import styles from 'Auth/styles';
import { ConsoleLogger as Logger } from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import PasswordInput from './PasswordInput';
import PasswordConfirm from './PasswordConfirm';

const logger = new Logger('ForgotPassword');

const CodeInput = props => {
  return (
    <>
      <Grid container>
        <Grid item xs={1}>
          <EmailIcon style={{ fontSize: 30 }} />
        </Grid>
        <Grid item xs={11}>
          <Typography variant="body2" style={{ marginBottom: 10 }}>
            We sent this code to you in an email. If you didn't get it, please
            hit the 'resend code' button below.
          </Typography>
        </Grid>
      </Grid>
      <ReactCodeInput
        inputMode="numeric"
        key="code"
        name="code"
        type="number"
        fields={6}
        onChange={value => {
          props.handleInputChange({
            target: {
              name: 'code',
              value,
              type: 'text',
            },
          });
        }}
        inputStyle={{
          fontFamily: 'monospace',
          margin: '4px',
          MozAppearance: 'textfield',
          borderRadius: '3px',
          fontSize: '26px',
          height: '40px',
          width: '28px',
          paddingLeft: '7px',
          backgroundColor: 'white',
          color: 'black',
          border: '1px solid #777',
        }}
      />
    </>
  );
};

export default withStyles(styles)(
  class CustomForgotPassword extends ForgotPassword {
    constructor(props) {
      super(props);

      this._validAuthStates = ['forgotPassword'];

      // The parent component only serializes the inputs when
      // making XHR requests. Therefore, we must keep redundant
      // copies in state to use for real-time user feedback.
      if (!this.state) {
        this.state = {};
      }
      this.state.code = '';
      this.state.password = '';
      this.state.passwordConfirm = '';
      this.state.loading = '';
    }

    getUsernameFromInput() {
      const username = this.inputs.username || this.state.username || '';
      return username.toLowerCase().trim();
    }

    /**
     * Overriding `submit` component in order to navigate to
     * root after submit.
     */
    submit() {
      const { authData = {} } = this.props;
      const { code, password } = this.inputs;
      const username = this.getUsernameFromInput() || authData.username;

      if (!Auth || typeof Auth.forgotPasswordSubmit !== 'function') {
        throw new Error(
          'No Auth module found, please ensure @aws-amplify/auth is imported'
        );
      }
      Auth.forgotPasswordSubmit(username, code, password)
        .then(data => {
          logger.debug(data);
          window.location.href = '/';
        })
        .catch(err => this.error(err))
        .finally(() => this.setState({ loading: false }));
    }

    sendView() {
      return (
        <>
          <Typography variant="body2" style={{ marginBottom: 20 }}>
            Input the email address you signed up with and we'll send you a code
            in your email to initiate the password reset process.
          </Typography>
          <TextField
            required
            id="username"
            key="username"
            name="username"
            onChange={this.handleInputChange}
            label="Your email address"
            type="text"
            margin="normal"
            variant="outlined"
            autoComplete="off"
            inputProps={{
              'data-test': 'username-input',
            }}
            autoFocus
            fullWidth
          />
        </>
      );
    }

    submitView() {
      const { password } = this.state;

      return (
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h3" style={{ fontSize: 24, marginBottom: 20 }}>
              Step 1: Verification Code
            </Typography>
            <CodeInput
              handleInputChange={event => {
                this.handleInputChange(event);
                this.setState({ code: event.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              style={{ fontSize: 24, marginTop: 40, marginBottom: 20 }}
            >
              Step 2: New Password
            </Typography>
            <PasswordInput
              password={password}
              onChange={event => {
                this.handleInputChange(event);
                this.setState({ password: event.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              style={{ fontSize: 24, marginTop: 40, marginBottom: 20 }}
            >
              Step 3: Confirm Password
            </Typography>
            <PasswordConfirm
              isConfirmed={this.isPasswordSet && this.isPasswordConfirmed}
              onChange={event => {
                this.handleInputChange(event);
                this.setState({ passwordConfirm: event.target.value });
              }}
            />
          </Grid>
        </Grid>
      );
    }

    get isCodeEntered() {
      return this.state.code.length === 6;
    }

    get isPasswordSet() {
      return this.state.password.trim().length > 0;
    }

    get isPasswordConfirmed() {
      return this.state.passwordConfirm === this.state.password;
    }

    showComponent() {
      const { authData = {} } = this.props;

      return (
        <Card className={this.props.classes.root}>
          <CardContent>
            {this.state.delivery || authData.username
              ? this.submitView()
              : this.sendView()}
          </CardContent>
          <CardActions className={this.props.classes.actions}>
            {this.state.delivery || authData.username ? (
              <Button
                onClick={this.send}
                data-test="forgot-password-resend-code-link"
              >
                Resend code
              </Button>
            ) : (
              <Button
                onClick={() => (window.location.href = '/')}
                data-test="forgot-password-back-to-sign-in-link"
              >
                Back to sign in
              </Button>
            )}

            {this.state.delivery || authData.username ? (
              <Button
                onClick={this.submit}
                color="primary"
                variant="contained"
                data-test="forgot-password-submit-button"
                disabled={
                  !this.isPasswordSet ||
                  !this.isPasswordConfirmed ||
                  !this.isCodeEntered
                }
                loading={this.state.loading}
              >
                Submit
              </Button>
            ) : (
              <Button
                onClick={event => {
                  this.send(event);
                }}
                color="primary"
                variant="contained"
                data-test="forgot-password-send-code-button"
              >
                Send code
              </Button>
            )}
          </CardActions>
        </Card>
      );
    }

    /**
     * The `render` method is being overriden in order to implement
     * the `alwaysRender` custom prop, which allows us to let the
     * URL determine whether or not to render the ForgotPassword
     * component. This is useful so that we can link to the ForgotPassword
     * view. Otherwise, the wrapping Authenticator component
     * completely determines what is rendered based on `authState`.
     */
    render() {
      if (this.props.alwaysRender) {
        return this.showComponent(this.props.theme || AmplifyTheme);
      }

      if (!this._validAuthStates.includes(this.props.authState)) {
        this._isHidden = true;
        this.inputs = {};
        return null;
      }

      if (this._isHidden) {
        this.inputs = {};
        const { track } = this.props;
        if (track) track();
      }
      this._isHidden = false;

      return this.showComponent(this.props.theme || AmplifyTheme);
    }
  }
);
