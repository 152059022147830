export const MapEntries: [string, RegExp][] = [
  ['User does not exist', /user.*not.*exist/i],
  ['User already exists', /user.*already.*exist/i],
  ['Incorrect email or password', /incorrect.*username.*password/i],
  ['Invalid password format', /validation.*password/i],
  ['Incorrect email or password', /UserMigration.*invalid.*credentials/i],
  ['Incorrect email or password', /UserMigration.*bad.*credentials/i],
];

export default function ErrorMessageMap(message: string): string {
  const matches = MapEntries.filter(entry => entry[1].test(message));
  if (matches.length === 0) {
    return message;
  }

  return matches[0][0];
}
