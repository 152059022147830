import React from 'react';
import Auth from '@aws-amplify/auth';
import { Greetings } from 'aws-amplify-react';
import Logo from '@bit/greenenergytrading.geo.brand.logo';
import styles from 'Auth/styles';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import trimStart from 'lodash/trimStart';

const HOME_URL = process.env.REACT_APP_WEB_ENDPOINT;

export default withRouter(
  withStyles(styles)(
    class CustomGreetings extends Greetings {
      constructor(props) {
        super(props);
        this._validAuthStates = ['signedIn'];
      }

      userGreetings() {
        const user = this.props.authData || this.state.authData;
        const nameFromAttr = user.attributes
          ? user.attributes.name ||
            (user.attributes.given_name
              ? user.attributes.given_name + ' ' + user.attributes.family_name
              : undefined)
          : undefined;
        const name = nameFromAttr || user.name || user.username;

        const queryParams = qs.parse(
          trimStart(this.props.location.search, '?')
        );

        return (
          <>
            <Typography
              variant="body1"
              style={{ fontSize: 18, marginBottom: 20 }}
            >
              You're currently logged into Geo as {name}.
            </Typography>
            {queryParams['redirect'] ? (
              <Typography
                variant="body1"
                style={{ fontSize: 18, marginBottom: 20 }}
              >
                <em>Redirecting to {queryParams['redirect']}...</em>
              </Typography>
            ) : null}
          </>
        );
      }

      render() {
        const authState = this.props.authState || this.state.authState;
        const signedIn = authState === 'signedIn';

        const greeting = signedIn
          ? this.userGreetings()
          : this.noUserGreetings();

        if (!greeting) {
          return null;
        }

        return (
          <Card className={this.props.classes.root}>
            <CardContent>
              <div style={{ textAlign: 'center' }}>
                <Logo width={150} />
              </div>
              {greeting}
            </CardContent>

            {signedIn ? (
              <CardActions>
                <Button
                  onClick={() => {
                    Auth.signOut().then(() => {
                      localStorage.removeItem('idToken');
                      localStorage.removeItem('currentUser');
                    });
                  }}
                >
                  Sign out
                </Button>
                <Button href={HOME_URL}>Home</Button>
              </CardActions>
            ) : null}
          </Card>
        );
      }
    }
  )
);
