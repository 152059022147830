import React from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

interface PasswordInputProps {
  password: string;
  onChange: (event: any) => void;
}

export default (props: PasswordInputProps) => {
  const { password, onChange } = props;

  const passwordPolicyRules = [
    {
      label: 'At least 8 characters',
      pass: password.length >= 8,
    },
    {
      label: 'Contains upper case letter(s)',
      pass: /[A-Z]/g.test(password),
    },
    {
      label: 'Contains lower case letter(s)',
      pass: /[a-z]/g.test(password),
    },
    {
      label: 'Contains number(s)',
      pass: /\d/g.test(password),
    },
  ];

  const passwordErrors = [
    {
      label: 'Whitespace not allowed in password',
      fail: /\s/g.test(password),
    },
  ].filter(errorRule => {
    return errorRule.fail;
  });

  return (
    <>
      <Typography variant="body2">
        Password must meet all conditions:
      </Typography>
      <List dense>
        {passwordPolicyRules.map(rule => {
          return (
            <ListItem key={rule.label}>
              <ListItemIcon>
                {rule.pass ? (
                  <CheckCircleOutlineIcon style={{ color: 'green' }} />
                ) : (
                  <RadioButtonUncheckedIcon />
                )}
              </ListItemIcon>
              <ListItemText primary={rule.label} />
            </ListItem>
          );
        })}

        {passwordErrors.map(rule => {
          return (
            <ListItem key={rule.label}>
              <ListItemIcon>
                <ErrorOutlineIcon style={{ color: 'red' }} />
              </ListItemIcon>
              <ListItemText primary={rule.label} />
            </ListItem>
          );
        })}
      </List>
      <TextField
        required
        id="password"
        key="password"
        name="password"
        onChange={onChange}
        label="Enter a new password"
        type="password"
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
      />
    </>
  );
};
