import React from 'react';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

interface PasswordConfirmProps {
  isConfirmed: boolean;
  onChange: (event: any) => void;
}

export default (props: PasswordConfirmProps) => {
  const { isConfirmed, onChange } = props;

  return (
    <>
      <List dense>
        <ListItem>
          <ListItemIcon>
            {isConfirmed ? (
              <CheckCircleOutlineIcon style={{ color: 'green' }} />
            ) : (
              <RadioButtonUncheckedIcon />
            )}
          </ListItemIcon>
          <ListItemText
            primary={
              isConfirmed ? 'Password confirmed' : 'Passwords must match'
            }
          />
        </ListItem>
      </List>
      <TextField
        required
        id="passwordconfirm"
        key="passwordconfirm"
        name="passwordconfirm"
        onChange={onChange}
        label="Re-enter password"
        type="password"
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
      />
    </>
  );
};
