import React from 'react';
import { Authenticator } from 'aws-amplify-react';

let domain = 'localhost';
if (['demo', 'production'].indexOf(process.env.NODE_ENV) !== -1) {
  domain = 'geocreation.com.au';
}

const config = {
  region: 'ap-southeast-2',
  userPoolId: process.env.REACT_APP_USER_POOL_ID || '',
  userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  cookieStorage: {
    domain,
    secure: domain !== 'localhost',
  },
};

interface WrappedAuthenticatorProps {
  hide?: any[];
  hideDefault?: boolean;
  onStateChange?: (authState: string, data?: any) => void;
  errorMessage?: (message: string) => string;
}

const WrappedAuthenticator: React.FC<WrappedAuthenticatorProps> = props => {
  return (
    <Authenticator amplifyConfig={config} {...props}>
      {props.children}
    </Authenticator>
  );
};

export default WrappedAuthenticator;
