export default {
  root: {
    maxWidth: 500,
    margin: '70px auto',
    padding: 30,
  },
  link: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
};
