// @ts-nocheck
// Unfortunately, Typescript checking must be turned off in this file
// so that we can pass any props we want to our custom Amplify components.
// The parent components are not generic so we cannot extend their interfaces.

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ForgotPassword from 'Auth/ForgotPassword';
import Authenticator from 'Auth/Authenticator';
import Auth from 'Auth';

export default () => {
  return (
    <Switch>
      <Route
        path="/forgot_password"
        component={() => {
          return (
            <Authenticator hideDefault={true}>
              {/* @ts-ignore */}
              <ForgotPassword alwaysRender />
            </Authenticator>
          );
        }}
      />
      <Route
        path="/"
        component={() => (
          <Auth>
            <></>
          </Auth>
        )}
      />
    </Switch>
  );
};
